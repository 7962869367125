body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}
  
.main-content {
  padding-top: 0px; /* Adjust this value if the AppBar height changes */
}
  
  
/* Hide scrollbar */
html, body {
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}

html::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* Ensure the body takes the full height */
body {
  height: 100vh;
  margin: 0;
}
